import { useState } from 'react'

const useWindowResize = () => {
  const eventListener = 'resize'
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // Resize Window Hook
  const resizeWidth = () => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener(eventListener, handleResize)
    return () => window.removeEventListener(eventListener, handleResize)
  }

  return [windowWidth, resizeWidth]
}

const useWindowScroll = () => {
  const [pageOffset, setWindowScroll] = useState(window.window.pageYOffset)

  const scrollWindow = () => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setWindowScroll(window.pageYOffset)
      }
    }
  }

  return [pageOffset, scrollWindow]
}

const useUserActions = (dispatch, userActions) => {
  dispatch(userActions.checkPHPConnectionStatus(process.env.API_ENV))

  const interval = setInterval(() => {
    dispatch(userActions.checkPHPConnectionStatus(process.env.API_ENV))
  }, 60000)
  return () => {
    clearInterval(interval)
  }
}

const useFetchUser = (
  userSessionExpired,
  dispatch,
  userActions,
  csrfCookie,
  csrfCoinigy,
  settingsCurr
) => {
  if (userSessionExpired === false) {
    dispatch(userActions.fetchUserSettings(csrfCoinigy))
    dispatch(userActions.fetchExchRate(settingsCurr))

    if (
      typeof document !== `undefined` &&
      document.cookie.indexOf('coinigy_csrf_token') > -1
    ) {
      csrfCookie.current = document.cookie
        .split('; ')
        .find(row => row.startsWith('coinigy_csrf_token='))
        .split('=')[1]
      dispatch(userActions.storeCsrfCookie(csrfCookie.current))
    }
  }
}

const useScrollOffset = (userActions, dispatch, offset) => {
  const isOffset = offset < 30 && true
  dispatch(userActions.setHeaderDark(isOffset))
}

const useHandleDarkModeSwitch = async (
  dispatch,
  userActions,
  userDarkModeSelector,
  userSessionExpired,
  newToken,
  userDarkModeSettings
) => {
  dispatch(userActions.setDarkMode(!userDarkModeSelector))
  if (userSessionExpired === false) {
    await dispatch(
      userActions.saveUserDarkMode(newToken, !userDarkModeSettings)
    )
    await dispatch(userActions.fetchUserSettings(newToken))
  }
  document.cookie = "darkModeCookie" + "=" + (userDarkModeSelector === true ? "Light" : "Dark") + "; path=/";
}

export {
  useWindowResize,
  useUserActions,
  useFetchUser,
  useScrollOffset,
  useHandleDarkModeSwitch,
  useWindowScroll,
}
