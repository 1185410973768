import React, {useEffect} from 'react'
import loadable from '@loadable/component'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { Title, Section, Box, Text, Button } from '../../components/Core'
import { breakpoints } from '../../utils'
import { StaticImage } from "gatsby-plugin-image"


import {
  useWindowResize,
} from '../../components/Header/HeaderHooks'


import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const ExchMarketsTable = loadable(() =>
  import('../../components/Table/ExchMarketsTable')
)

const ExchMarketsTableWithFooter = loadable(() =>
  import('../../components/Table/ExchMarketsTableWithFooter')
)

const ButtonStyle = styled(Button)`
@media (max-width: ${breakpoints.md}px) {
    width: 100%;
}
`
const Hero = () => {
  const { t } = useTranslation( ['landing', 'tables'], { useSuspense: false });
  
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const totalRecords = useSelector(state => state.exchData.totalRecords)
  const tableRows = useSelector(state => state.user.tableRows)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  // Custom Hooks
  const [windowWidth, resizeWidth] = useWindowResize()
  
  useEffect(() => {
    resizeWidth()
  }, [])


  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section className="position-relative" bg={userDarkMode ? 'black' : 'bg'}>
        <Container>
          <Row
            style={{ justifyContent: 'space-between' }}
            className="d-flex py-lg-5 py-5 mb-5 pt-lg-4 mt-lg-5"
          >
            <Col xl="6" lg="6" className="order-lg-1 order-2" >
              <div
                // data-aos="fade-right"
                // data-aos-duration="750"
                // data-aos-once="true"
                // data-aos-delay="500"
              >
                <Box>
                  <Title variant={"hero-home"} style={{ color: userDarkMode ? '#1E72EC' : '#2F5C91' }}>
                    {t('edge')}
                  </Title>
                  <Text
                    color={userDarkMode ? 'lightShade' : 'darkShade'}
                    mb={4}
                  >
                    {t('lowTx')}
                  </Text>
                  <a
                    aria-label={t('signup')}
                    href={process.env.LS_SIGNUP}
                    rel="noopener noreferrer"
                  >
                    <ButtonStyle
                      type="submit"
                      style={{ fontSize: '16px' }}
                      background={'#124D95'}
                    >
                      {t('startTrading')}
                    </ButtonStyle>
                  </a>
                  {/* <Text
                    color={userDarkMode ? 'lightShade' : 'darkShade'}
                    mt={3}
                  >
                    {t('demo')} <a
                      aria-label={t('signup')}
                      href={process.env.LS_SIGNUP}
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'underline' }}
                    >
                        <span style={{ color: userDarkMode ? '#3B96FF' : '#3377C6' }}>{t('getStarted')}</span>
                    </a>
                  </Text> */}
                  
                </Box>
              </div>
            </Col>
            <Col
              xl="6"
              lg="6"
              pd={0}
              className="order-1 order-lg-2 d-flex align-items-center mx-lg-0 jumbotron-fluid"
            >
              <div className="position-relative">
                <div
                  className="img-main justify-content-center"
                  // data-aos="fade-down"
                  // data-aos-duration="750"
                  // data-aos-once="true"
                  // data-aos-delay="500"
                >
                    <StaticImage
                      className="img-fluid mb-5 mb-lg-0"
                      src="../../assets/image/webp/hero-img-2.webp"
                      alt="Hermo image"
                    />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg="12">
              <Title color={userDarkMode ? '#FAFAFA' : 'black'}>
                {t('tradeTheMost')}
              </Title>
              <Text
                color={userDarkMode ? 'lightShade' : 'darkShade'}
                mb={4}
              >
                {t('lsOffers')}
              </Text>
              {
                totalRecords < tableRows ?
                  <ExchMarketsTable
                    title="Lightspeed Crypto markets"
                    exchSlug="lightspeed-crypto"
                    exchCode="LSCX"
                    exchName="Lightspeed Crypto"
                  />
                  :
                  <ExchMarketsTableWithFooter
                    title="Lightspeed Crypto markets"
                    exchSlug="lightspeed-crypto"
                    exchCode="LSCX"
                    exchName="Lightspeed Crypto"
                  />
              }

            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Hero
